import PropTypes from 'prop-types';

import React from 'react';
import $ from 'jquery';
import Downshift from 'downshift';
import 'url-search-params-polyfill';

function getLastWord(text) {
  if (!text) {
    return '';
  }
  return text.split(' ').splice(-1)[0] || '';
}

function shouldItemRender(item, value) {
  const lastWord = getLastWord(value);
  return item.value.toLowerCase().indexOf(lastWord.toLowerCase()) > -1;
}

function replaceLastWord(text, newLastWord) {
  const lastIndex = text.lastIndexOf(' ');
  const prefix = text.substring(0, lastIndex);
  if (prefix) {
    return `${prefix} ${newLastWord}`;
  }
  return newLastWord;
}

function stateReducer(state, changes) {
  // prevents clearing inputValue when user clicks elsewhere
  // prevent clearing inputValue when tabbing
  switch (changes.type) {
    case Downshift.stateChangeTypes.mouseUp:
    case Downshift.stateChangeTypes.blurInput:
      return {
        isOpen: false,
      };
    default:
      return changes;
  }
}

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    const params = new URLSearchParams(window.location.search);
    const q = params.get('q');
    const initialState = {
      value: q,
      completions: [],
    };
    this.state = initialState;
    this.handleInputValueChange = this.handleInputValueChange.bind(this);
  }

  componentDidMount() {
    this.getCompletions();
  }

  handleInputValueChange(inputValue) {
    // Get new completions everytime the inputValue changes
    this.setState({ value: inputValue }, () => {
      this.getCompletions();
    });
  }

  getCompletions() {
    $.ajax({
      url: '/search/autocomplete',
      method: 'GET',
      data: { q: getLastWord(this.state.value) },
      contentType: 'application/json',
    }).done((jsonData) => {
      const data = jsonData;
      if (!data.completions) {
        data.completions = [];
      }
      // Just replace the last word with the completion
      const newcomps = data.completions.map((c) => ({
        ...c,
        value: replaceLastWord(this.state.value, c.value),
      }));
      this.setState({
        completions: newcomps,
      });
    });
  }

  render() {
    const menuStyle = {
      borderRadius: '3px',
      boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
      background: 'rgba(255, 255, 255, 0.9)',
      padding: '2px 0',
      fontSize: '100%',
      position: 'fixed',
      overflow: 'auto',
      zIndex: '10',
      maxHeight: '50%',
    };

    /* eslint-disable jsx-a11y/no-autofocus */
    const extraProps = {
      id: 'base1-search-bar-input',
      className: 'form-control',
      placeholder: 'Part Number, Description ...',
      type: 'text',
      name: 'q',
      autoFocus: !!this.props.isAutoFocus,
    };

    /* eslint-disable no-unused-vars */
    return (
      <Downshift
        itemToString={(item) => (item ? item.value : '')}
        onInputValueChange={this.handleInputValueChange}
        inputValue={this.state.value || ''}
        stateReducer={stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getToggleButtonProps,
          isOpen,
          inputValue,
          highlightedIndex,
          selectedItem,
          getRootProps,
        }) => (
          <div>
            <div
              className="input-group input-group-lg"
              {...getRootProps({}, { suppressRefError: true })}
            >
              <input {...getInputProps(extraProps)} />
              <div className="input-group-btn">
                <button
                  className="mc-search-bar__button h-100 align-self-center btn btn-default"
                  type="submit"
                >
                  <span className="fa fa-search fa-2x text-white" />
                </button>
              </div>
            </div>
            <ul {...getMenuProps({ style: { ...menuStyle, display: isOpen ? 'block' : 'none' } })}>
              {isOpen
                ? this.state.completions
                  .filter((item) => shouldItemRender(item, inputValue))
                  .map((item, index) => (
                    <li
                      {...getItemProps({
                        key: item.id,
                        index,
                        item,
                        style: {
                          backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
                          fontWeight: selectedItem === item ? 'bold' : 'normal',
                        },
                      })}
                    >
                      {item.value}
                    </li>
                  ))
                : null}
            </ul>
          </div>
        )}
      </Downshift>
    );
  }
}

SearchInput.propTypes = {
  isAutoFocus: PropTypes.bool,
};

export default SearchInput;
