// To enable polyfills per babel docs
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import DOM from 'react-dom';
import SearchInput from './components/SearchInput';

DOM.render(
  <SearchInput isAutoFocus />,
  document.getElementById('search-input'),
);
